<template>
    <div class="contain-box">
        <p class="try-tip ellipsis" v-if="trialTip" :title="trialTip">
            <SvgIcon name="notification" color="#2b32c9" :size="22" />
            {{ trialTip }}
        </p>
        <div v-if="showContent === 'data'" :class="['content-box', { trial: trialTip }]">
            <CardBox :header="false">
                <template #contain>
                    <p class="recent-run-title">{{ t('project.recentlyRun') }}</p>
                    <div class="project-box">
                        <div class="project-list" style="margin: 0" v-for="item in lastRuningList" :key="item.name" @click="startProj(item)">
                            <p class="project-name1 ellipsis" :title="`${item.name}`">{{ item.name }}</p>
                            <p class="project-time">{{ formatDate(item.openTime) }}</p>
                        </div>
                        <div class="no-data" v-if="lastRuningList && lastRuningList.length === 0">
                            <icon-empty :size="48" />
                            <p>{{ t('noData') }}</p>
                        </div>
                    </div>
                </template>
            </CardBox>
            <CardBox :header="false" v-if="spaceData">
                <template #contain>
                    <div class="statistics-header">
                        <div style="display: flex; align-items: center">
                            <template
                                v-if="
                                    (spaceData.trialExpireDate && !valiateExpireTime(spaceData.trialExpireDate)) ||
                                    (!spaceData.trial && spaceData.trialExpireDate && valiateExpireTime(spaceData.trialExpireDate))
                                ">
                                <SvgIcon
                                    v-if="valiateExpireTime(spaceData.trialExpireDate)"
                                    :name="store.getters.isEnglish ? 'trial-en' : 'trial'"
                                    color="#666"
                                    :size="30" />
                                <SvgIcon v-else :name="store.getters.isEnglish ? 'trial-en' : 'trial'" color="#2b32c9" :size="30" />
                                <LineChartItem :data="{ title: t('resource.spaceNum'), num: spaceData.trialUsedCount, total: spaceData.trialMaxCount }" />
                                <LineChartItem
                                    :data="{ title: t('resource.runSpace'), num: spaceData.trialRunningUsedCount, total: spaceData.trialRunningMaxCount }" />
                                <i class="split-line" v-if="spaceData.trial"></i>
                            </template>
                            <template v-if="spaceData.trial">
                                <SvgIcon name="team1" v-if="valiateExpireTime(spaceData.teamExpireDate)" color="#666" :size="30" />
                                <SvgIcon name="team1" v-else color="#2b32c9" :size="30" />
                                <LineChartItem :data="{ title: t('resource.spaceNum'), num: spaceData.teamUsedCount, total: spaceData.teamMaxCount }" />
                                <LineChartItem
                                    :data="{ title: t('resource.runSpace'), num: spaceData.teamRunningUsedCount, total: spaceData.teamRunningMaxCount }" />
                            </template>
                        </div>
                        <Button class="btn-fill" type="primary" size="large" @click="addProj">
                            {{ t('project.addProj') }}
                        </Button>
                    </div>
                    <div class="project-box" style="margin: 0 40px" ref="projectList">
                        <div class="project-list flex-box" v-for="item in projList" :key="item.name" @click="startProj(item)">
                            <div class="flex-left">
                                <p class="project-name">
                                    <SvgIcon :name="item.platform === 'nuxiums,runthos' ? 'allinone' : item.platform" color="#2b32c9" :size="30" />
                                    <span class="ellipsis" :title="item.name"> {{ item.name }} </span>
                                    <span class="ellipsis">
                                        <SvgIcon
                                            v-if="item.trial === 0 && valiateExpireTime(spaceData.trialExpireDate)"
                                            :name="store.getters.isEnglish ? 'trial-en' : 'trial'"
                                            :title="t('trialExpiredTip')"
                                            color="#666"
                                            :size="34" />
                                        <SvgIcon
                                            v-if="item.trial === 0 && !valiateExpireTime(spaceData.trialExpireDate)"
                                            :name="store.getters.isEnglish ? 'trial-en' : 'trial'"
                                            :title="t('tip.trialExpriedTip', { time: moment(spaceData.trialExpireDate).format('YYYY-MM-DD') })"
                                            color="#2b32c9"
                                            :size="34" />
                                    </span>
                                </p>
                                <p class="project-time">
                                    <Space :size="30">
                                        <span>{{ t('project.createAt') }}{{ formatDate(item.createTime) }}</span>
                                        <span v-if="item.openTime">{{ t('project.recentlyOpened') }}{{ formatDate(item.openTime) }}</span>
                                    </Space>
                                </p>
                            </div>
                            <div class="flex-right" @click.stop="">
                                <Space :size="30">
                                    <SvgIcon
                                        key="1"
                                        v-if="item.status === 1"
                                        name="suspend"
                                        color="#2b32c9"
                                        :tip="t('stop')"
                                        @click="getProjStop(item)"
                                        hoverColor="#2bc954"
                                        :size="28" />
                                    <span v-else-if="item.status === 2">{{ t('tip.expiredTip') }}</span>
                                    <span v-else-if="item.status === 3">{{ t('expired') }}</span>
                                    <SvgIcon
                                        v-else-if="item.status === 4"
                                        name="suspend"
                                        key="2"
                                        class="icon-loading"
                                        color="#2b32c9"
                                        :tip="t('project.stopping')"
                                        hoverColor="#2b32c9"
                                        :size="28" />
                                    <SvgIcon
                                        key="3"
                                        name="set-up"
                                        color="#2b32c9"
                                        :tip="t('project.setUp')"
                                        hoverColor="#2bc954"
                                        :size="28"
                                        @click="modifyProj(item)" />
                                    <SvgIcon
                                        key="4"
                                        name="delete"
                                        color="#2b32c9"
                                        :tip="t('delete')"
                                        hoverColor="#2bc954"
                                        :size="28"
                                        @click="deleteFunc(item)" />
                                </Space>
                            </div>
                        </div>
                    </div>
                    <Pagination
                        :class="[{ 'hide-page-list': pageConf.total <= pageConf.pageSizeOptions[0] }]"
                        v-model="pageConf.current"
                        :total="pageConf.total"
                        :show-total="pageConf.showTotal"
                        :show-page-size="pageConf.showPageSize"
                        :page-size-options="pageConf.pageSizeOptions"
                        :page-size="pageConf.pageSize"
                        @change="pageConf.onChange"
                        @page-size-change="pageConf.onPageSizeChange" />
                </template>
            </CardBox>
        </div>
        <template v-if="showContent === 'nodata'">
            <div :class="['no-data-contain', { trial: trialTip }]">
                <div class="statistics-header">
                    <div style="display: flex; align-items: center" v-if="spaceData && spaceData.hasResource">
                        <template
                            v-if="
                                (spaceData.trialExpireDate && !valiateExpireTime(spaceData.trialExpireDate)) ||
                                (!spaceData.trial && spaceData.trialExpireDate && valiateExpireTime(spaceData.trialExpireDate))
                            ">
                            <SvgIcon
                                v-if="valiateExpireTime(spaceData.trialExpireDate)"
                                :name="store.getters.isEnglish ? 'trial-en' : 'trial'"
                                color="#666"
                                :size="30" />
                            <SvgIcon v-else :name="store.getters.isEnglish ? 'trial-en' : 'trial'" color="#2b32c9" :size="30" />
                            <LineChartItem :data="{ title: t('resource.spaceNum'), num: spaceData.trialUsedCount, total: spaceData.trialMaxCount }" />
                            <LineChartItem
                                :data="{ title: t('resource.runSpace'), num: spaceData.trialRunningUsedCount, total: spaceData.trialRunningMaxCount }" />
                            <i class="split-line" v-if="spaceData.trial"></i>
                        </template>
                        <template v-if="spaceData.trial">
                            <SvgIcon name="team1" v-if="valiateExpireTime(spaceData.teamExpireDate)" color="#666" :size="30" />
                            <SvgIcon name="team1" v-else color="#2b32c9" :size="30" />
                            <LineChartItem :data="{ title: t('resource.spaceNum'), num: spaceData.teamUsedCount, total: spaceData.teamMaxCount }" />
                            <LineChartItem
                                :data="{ title: t('resource.runSpace'), num: spaceData.teamRunningUsedCount, total: spaceData.teamRunningMaxCount }" />
                        </template>
                    </div>
                </div>
                <div class="no-data-page" v-if="spaceData">
                    <div class="no-data-img"></div>
                    <p class="no-data-text">{{ t(spaceData.hasResource ? 'tip.noProjTip' : 'tip.noResourceTip') }}</p>
                    <Button class="btn-fill" type="primary" size="large" @click="addProj" v-if="spaceData.hasResource">
                        {{ t('project.addProj') }}
                    </Button>
                </div>
            </div>
        </template>
        <AddProject
            :operation="operation"
            :projData="projData"
            :platforms="projectPlatforms"
            :showModal="showProjectModal"
            @closeModal="showProjectModal = false"
            @updateData="getData" />
        <Modal width="500px" modalClass="space-modal" v-model:visible="modalVisible" @close="handleClose" :mask-closable="false">
            <template #title>
                <p class="modal-title">{{ t('deleteSpace') }}</p>
            </template>
            <div>
                <Form ref="formDom" :model="formData" :rules="formRule" layout="vertical" autocomplete="off">
                    <p class="space-tip">{{ t('tip.deleteSpaceTip', { name: formData.originName }) }}</p>
                    <FormItem field="spaceName" :validate-trigger="['blur']">
                        <Input
                            v-model="formData.spaceName"
                            @blur="formData.spaceName = formData.spaceName.trim()"
                            spellcheck="false"
                            :placeholder="formData.originName" />
                    </FormItem>
                </Form>
            </div>
            <template #footer>
                <Button class="btn-fill" type="primary" :disabled="!formData.spaceName" html-type="submit" @click="handleSubmit" :loading="loading">
                    {{ t('ok') }}
                </Button>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, inject, reactive, ref, watch } from 'vue'
import AddProject from '@/components/AddProjects.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Role } from '@/store'
import { Form } from '@arco-design/web-vue'
import CardBox from '@/components/CardBox.vue'
import { valiateExpireTime } from '@/utils/utils'
import LineChartItem from '@/components/LineChartItem.vue'
export default defineComponent({
    components: {
        CardBox,
        AddProject,
        LineChartItem
    },
    setup() {
        const projectList = ref(null || HTMLDivElement)
        const t = inject('$t')
        const store = useStore()
        const http = inject('$http')
        const moment = inject('$moment')
        const msg = inject('$message')
        const operation = ref(1) // 1、添加；0、修改
        const showProjectModal = ref(false)
        const spaceData = ref(null)
        const trialTip = ref('')
        const projList = ref([])
        const lastRuningList = ref(null)
        const projectPlatforms = ref(null)
        const router = useRouter()
        const showContent = ref('')
        const modalVisible = ref(false)
        const formDom = ref(null || Form)
        const loading = ref(false)
        const statusMap = {
            null: { name: 'compile', tip: t('project.build') },
            1: { name: 'compile', tip: t('project.build') },
            2: { name: 'compile-success', tip: t('project.success') },
            3: { name: 'compile-failure', tip: t('project.fail') },
            4: { name: 'compile-failure', tip: t('project.fail') }
        }
        const pageConf = reactive({
            showTotal: true,
            showPageSize: true,
            hideOnSinglePage: false,
            pageSizeOptions: [30, 60, 100],
            current: 1,
            pageSize: 30,
            total: 1,
            onChange: (data) => {
                pageConf.current = data
                getData()
            },
            onPageSizeChange: (data) => {
                pageConf.current = 1
                pageConf.pageSize = data
                getData()
            }
        })

        const formData = reactive({
            spaceName: '',
            originName: '',
            id: ''
        })

        const formRule = {
            spaceName: [
                {
                    validator: (val, callback) => {
                        if (!val) {
                            callback(t('holder.inputHolder', { key: t('resource.spaceName') }))
                        } else if (val !== formData.originName) {
                            callback(t('tip.spaceNameDifferentTip'))
                        } else {
                            callback()
                        }
                    }
                }
            ]
        }
        const deleteFunc = (item) => {
            modalVisible.value = true
            formData.spaceName = ''
            formData.id = item.id
            formData.originName = item.name
        }
        const handleClose = () => {
            loading.value = false
            formDom.value.resetFields()
            modalVisible.value = false
        }
        const handleSubmit = () => {
            formDom.value.validate((errors) => {
                if (!errors) {
                    loading.value = true
                    http.account.deleteLeaderSpace(formData.id).then(() => {
                        getData()
                        msg.info(t('delSucc'))
                        modalVisible.value = false
                        loading.value = false
                    })
                }
            })
        }

        const getTeamInfo = (fun) => {
            http.account.getTeamInfoById(store.state.userData.teamId).then(({ data }) => {
                if (data.platforms) {
                    projectPlatforms.value = data.platforms = data.platforms.split(',').sort()
                }
                trialTip.value = data.remindMsg
                spaceData.value = data
                fun && fun()
            })
        }

        const lastRunningProjects = () => {
            http.project.lastRunningProjects(store.state.userData.teamId).then(({ data }) => {
                lastRuningList.value = data
            })
        }

        const getData = () => {
            if (!store.state.userData) return
            const params = {
                page: pageConf.current - 1,
                size: pageConf.pageSize,
                teamId: store.state.userData.teamId
            }
            if (projectList.value) {
                projectList.value.scrollTop = 0
            }
            http.project.getProjectList(params).then(({ data }) => {
                getTeamInfo()
                showContent.value = data.total > 0 ? 'data' : 'nodata'
                pageConf.total = data.total
                projList.value = data.data
                lastRunningProjects()
            })
        }

        watch(
            () => store.state.userData,
            () => {
                pageConf.current = 1
                pageConf.pageSize = 30
                getData()
            },
            {
                immediate: true
            }
        )

        const projData = ref(null)
        const addProj = () => {
            getTeamInfo(() => {
                if (spaceData.value.trial && valiateExpireTime(spaceData.value.teamExpireDate)) {
                    msg.info(t('tip.teamRenewalTip'))
                    return
                }
                if (
                    spaceData.value.trial &&
                    !valiateExpireTime(spaceData.value.teamExpireDate) &&
                    spaceData.value.teamMaxCount <= spaceData.value.teamUsedCount
                ) {
                    msg.info(t('tip.teamNumRenewalTip'))
                    return
                }
                if (!spaceData.value.trial && valiateExpireTime(spaceData.value.trialExpireDate)) {
                    msg.info(t('tip.trialRenewalTip'))
                    return
                }
                if (
                    !spaceData.value.trial &&
                    !valiateExpireTime(spaceData.value.trialExpireDate) &&
                    spaceData.value.trialMaxCount <= spaceData.value.trialUsedCount
                ) {
                    msg.info(t('tip.trialNumRenewalTip'))
                    return
                }
                operation.value = 1
                showProjectModal.value = true
            })
        }

        const getProjStop = (item) => {
            item.status = 4
            http.project.getProjStop(item.id).then(({ data }) => {
                setTimeout(() => {
                    item.status = 0
                    item.trial ? (spaceData.value.teamRunningUsedCount -= 1) : (spaceData.value.trialRunningUsedCount -= 1)
                }, 10000)
            })
        }

        const modifyProj = (item) => {
            operation.value = 0
            projData.value = item
            showProjectModal.value = true
        }

        const startProj = (item) => {
            if (item.status === 1) {
                window.open(`/startup/${item.id}`, '_blank')
                setTimeout(() => {
                    getData()
                }, 1000)
            } else if (item.status === 4) {
                return
            } else if ((item.status === 3 || item.status === 2) && item.trial === 0) {
                msg.info(t('tip.trialRenewalTip1'))
            } else if ((item.status === 3 || item.status === 2) && item.trial === 1) {
                msg.info(t('tip.teamRenewalTip'))
            } else {
                getTeamInfo(() => {
                    if (item.trial === 0 && spaceData.value.trialRunningUsedCount >= spaceData.value.trialRunningMaxCount) {
                        msg.info(t('tip.trialRunningTip'))
                        return
                    }
                    if (item.trial === 1 && spaceData.value.teamRunningUsedCount >= spaceData.value.teamRunningMaxCount) {
                        msg.info(t('tip.teamRunningTip'))
                        return
                    }
                    window.open(`/startup/${item.id}`, '_blank')
                    setTimeout(() => {
                        getData()
                    }, 1000)
                })
            }
        }

        const openHelp = (type) => {
            window.open(`/help?type=${type}`)
        }

        const formatDate = (date) => {
            if (moment().year() === moment(date).year()) {
                return moment(date).format('MM-DD HH:mm')
            } else {
                return moment(date).format('YYYY-MM-DD HH:mm')
            }
        }

        return {
            getProjStop,
            loading,
            modalVisible,
            formDom,
            formData,
            formRule,
            deleteFunc,
            handleClose,
            handleSubmit,
            trialTip,
            store,
            Role,
            t,
            statusMap,
            spaceData,
            operation,
            showProjectModal,
            lastRuningList,
            getData,
            addProj,
            modifyProj,
            projList,
            projData,
            moment,
            pageConf,
            startProj,
            router,
            openHelp,
            formatDate,
            projectPlatforms,
            projectList,
            showContent,
            valiateExpireTime
        }
    }
})
</script>

<style lang="less" scoped>
.recent-run-title {
    font-size: 20px;
    font-weight: 700;
    color: #2b32c9;
    padding: 24px;
}
:deep(.line-item .line-text > span:first-child) {
    font-size: 16px;
}
.statistics-header {
    height: 72px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    :deep(.line-text) {
        margin: 0 8px;
    }
    .split-line {
        height: 24px;
        width: 1px;
        background: #e9e9e9;
        display: inline-block;
        margin: 0 10px;
    }
}
.try-tip {
    height: 40px;
    background: rgba(43, 50, 201, 0.2);
    border-radius: 8px;
    line-height: 40px;
    padding: 0 20px;
    position: absolute;
    max-width: 80%;
    top: 80px;
}
.contain-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    .content-box {
        display: flex;
        flex-direction: row;
        height: 100%;
        &.trial {
            height: calc(100% - 20px);
            margin-top: 24px;
        }
    }
    .no-data {
        margin: 40px auto;
        color: rgb(169, 174, 184);
        text-align: center;
        width: 100px;
    }
    .no-data-contain {
        background: #fff;
        height: 100%;
        border-radius: 6px;
        box-shadow: 0px 2px 6px 0px rgba(177, 177, 177, 0.25);
        &.trial {
            height: calc(100% - 20px);
            margin-top: 24px;
        }
    }
    .no-data-page {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
        height: calc(100% - 180px);
        .no-data-text {
            color: #b7b7f5;
            font-size: 16px;
            padding: 30px 0 40px;
        }
        .no-data-img {
            height: 300px;
            width: 300px;
            background-image: url('~@/assets/img/no-proj.png');
        }
    }
    .card-box {
        height: 100%;
        &:first-child {
            width: 240px;
        }
        &:last-child {
            margin-left: 20px;
            width: calc(100% - 256px);
        }
    }
    .arco-pagination {
        float: right;
        margin-top: 10px;
        margin-right: 70px;
    }
    .project-box {
        overflow: auto;
        max-height: calc(100% - 140px);
        margin: 0 10px;
        .project-list {
            padding: 12px 20px;
            margin: 0 20px;
            text-align: left;
            border-bottom: 1px solid #f3f2f7;
            &:hover {
                background: #2b33c909;
                cursor: pointer;
            }
            .project-name1 {
                padding: 10px 0;
                font-size: 20px;
                font-weight: bold;
                height: 42px;
                white-space: pre;
                color: #333333;
            }
            .project-name {
                display: flex;
                align-items: center;
                padding: 10px 0;
                font-size: 20px;
                font-weight: bold;
                height: 42px;
                color: #333333;
                span:nth-child(1) {
                    margin-top: -4px;
                    margin-right: 18px;
                }
                span:nth-child(2) {
                    display: inline-block;
                    max-width: 50%;
                    height: 26px;
                    white-space: pre;
                }
                span:nth-child(3) {
                    max-width: 50%;
                    padding-left: 28px;
                    font-size: 14px;
                    height: 28px;
                    vertical-align: super;
                    text-align: left;
                    font-weight: normal;
                    color: #999999;
                }
            }
            .project-time {
                padding: 10px 0;
                font-size: 14px;
                color: #666666;
                span {
                    color: #999999;
                }
            }
            &:last-child {
                border-bottom: 0px;
            }
        }
    }
    .flex-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .flex-left {
            width: 480px;
        }
        .flex-right {
            display: flex;
            justify-content: flex-end;
            // width: 220px;
        }
    }
    .icon-loading {
        position: relative;
        top: -2px;
        padding: 2px;
        transform: scale(1);
        &::after {
            content: '';
            position: absolute;
            display: inline-block;
            animation: loading 1.5s infinite linear;
            height: 30px;
            width: 30px;
            left: 1px;
            top: 1px;
            background-image: url('~@/assets/img/circle.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 94%;
            z-index: -1;
        }
    }
}
@keyframes loading {
    0% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
</style>

<style lang="less">
.project-type-box {
    padding: 20px 20px 30px;
    margin: 0 10px;
    border-bottom: 2px solid #f3f2f7;
    text-align: left;
    &:last-child {
        border-bottom: 0px;
    }
    .project-title {
        padding-bottom: 10px;
        font-weight: bolder;
        font-size: 20px;
        color: #333333;
        text-align: center;
        padding-left: 20px;
        .icon-btn {
            height: unset;
            line-height: unset;
        }
    }
    .project-summary {
        font-size: 14px;
        color: #666666;
        padding-bottom: 20px;
        width: 200px;
        word-break: break-all;
    }
    .create-btn {
        text-align: center;
        .arco-btn {
            padding: 0px;
            font-size: 14px;
            background-color: #fff;
            color: #2b32c9;
            &:hover {
                background-color: #2b32c9;
                color: #fff;
            }
        }
    }
    .icon-btn {
        vertical-align: text-bottom;
    }
}

.download-src {
    width: 100px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    border-radius: 4px;
    background: #fff;
    z-index: 1;
    p {
        padding: 5px;
        text-align: center;
        border-bottom: 1px solid #c2c2c2;
        cursor: pointer;
        line-height: 18px;
        &:hover {
            color: #9699e2;
        }
        &:last-child {
            border-bottom: 0px;
        }
    }
}
.space-modal {
    .modal-title {
        font-size: 18px;
        font-weight: 700;
        color: #666666;
    }
    .space-tip {
        text-align: center;
        color: #666666;
        font-size: 14px;
        white-space: pre-wrap;
        padding: 0 40px 16px;
    }
}
</style>
