<template>
    <Modal width="550px" v-model:visible="visible" @close="handleClose" :mask-closable="false">
        <template #title>
            <p class="form-title">{{ operation ? t('project.addWorkspace') : t('project.modifyProj') }}</p>
        </template>
        <div>
            <Form ref="formDom" :model="formData" :rules="formRule" layout="vertical" autocomplete="off">
                <FormItem :label="t('systemEnv')" v-if="!!operation">
                    <div
                        v-for="item in platforms"
                        :key="item"
                        :class="['platform-btn', { active: item === formData.platform }]"
                        @click="formData.platform = item">
                        <SvgIcon :name="item" color="#666" :size="18" />
                        {{ item === 'nuxiums' ? 'Nuxiums' : 'RunthOS' }}
                    </div>
                    <div
                        v-if="platforms && platforms.length > 1"
                        :class="['platform-btn', { active: formData.platform === 'nuxiums,runthos' }]"
                        @click="formData.platform = 'nuxiums,runthos'">
                        <SvgIcon name="allinone" color="#666" :size="18" />
                        All In One
                    </div>
                </FormItem>
                <div style="margin-top: -16px; height: 30px">
                    <template v-if="formData.platform === 'nuxiums'">
                        <p class="form-tip" v-if="store.getters.isEnglish">Suitable for Nuxiums general application and Prolin application development</p>
                        <p class="form-tip" v-else>适用于Nuxiums普通应用和Prolin应用开发</p>
                    </template>
                    <template v-if="formData.platform === 'nuxiums,runthos'">
                        <p class="form-tip" v-if="store.getters.isEnglish">Suitable for Nuxiums, Prolin and RunthOS application development</p>
                        <p class="form-tip" v-else>适用于Nuxiums, Prolin和RunthOS应用开发</p>
                    </template>
                </div>
                <FormItem field="name" :validate-trigger="['blur']" :label="t('resource.spaceName')">
                    <Input
                        :title="!operation ? formData.name : ''"
                        v-model="formData.name"
                        spellcheck="false"
                        :placeholder="t('holder.enterHolder')"
                        @blur="formData.name = formData.name.trim()"
                        :maxlength="64" />
                </FormItem>
                <FormItem v-if="!!operation" field="repository" content-class="warehouse-item" :validate-trigger="['blur']" :label="t('project.codeSrc')">
                    <RadioGroup class="warehouse" v-model="warehouseType" @change="changeWarehouse">
                        <Radio :value="1">{{ t('git') }}</Radio>
                        <Radio :value="0">{{ t('empty') }}</Radio>
                    </RadioGroup>
                    <template v-if="!!operation && warehouseType">
                        <p class="form-tip" v-if="store.getters.isEnglish">
                            Please make sure that the <span @click="copySSH">SSH public key</span> has been added to the public key list of the warehouse,
                            otherwise it may cause failure to pull the code
                        </p>
                        <p class="form-tip" v-else>请确保<span @click="copySSH">SSH公钥</span>已加入个人公钥列表，否则可能导致拉取代码失败</p>
                    </template>
                    <Input
                        style="margin-top: 16px"
                        v-if="warehouseType"
                        v-model.trim="formData.repository"
                        spellcheck="false"
                        :disabled="!operation"
                        :placeholder="t('holder.warehouseAddressTip')"
                        :title="!operation ? formData.repository : ''"
                        @blur="validateField('repository')"
                        :maxlength="50" />
                </FormItem>

                <FormItem field="remark" :validate-trigger="['blur']" :label="t('project.desc')">
                    <Textarea
                        v-model="formData.remark"
                        @blur="formData.remark = formData.remark.trim()"
                        :placeholder="t('holder.enterHolder')"
                        show-word-limit
                        spellcheck="false"
                        :max-length="200"
                        :auto-size="{
                            minRows: 4,
                            maxRows: 5
                        }" />
                </FormItem>
            </Form>
        </div>
        <template #footer>
            <Button class="btn-fill" type="primary" html-type="submit" @click="handleSubmit" :loading="loading">
                {{ t('ok') }}
            </Button>
        </template>
    </Modal>
</template>

<script>
import { defineComponent, ref, watch, reactive, inject } from 'vue'
import { Form } from '@arco-design/web-vue'
import { useStore } from 'vuex'
export default defineComponent({
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        operation: {
            type: Number,
            default: 1 // 1、添加；0、修改
        },
        projData: {
            type: Object
        },
        platforms: {
            type: Array
        }
    },
    setup(props, ctx) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const clipboard = require('@/utils/clipboard').clipboard
        const t = inject('$t')
        const store = useStore()
        const http = inject('$http')
        const formDom = ref(null || Form)
        const loading = ref(false)
        const msg = inject('$message')
        const visible = ref(props.showModal)
        const warehouseType = ref(1)
        const formData = reactive({
            name: '',
            repository: '',
            remark: '',
            platform: ''
        })

        const formRule = {
            name: [
                { required: true, message: t('tip.emptyTip', { key: t('project.projectName') }) },
                { type: 'string', maxLength: 64, message: t('tip.projectTitleRegTip') }
            ],
            repository: [
                {
                    required: true,
                    validator: (val, callback) => {
                        if (warehouseType.value === 1) {
                            const reg = /^(ssh:\/\/git@|git@|https:\/\/|http:\/\/).*(\.git)$/
                            if (formData.repository === '') {
                                callback(t('tip.emptyTip', { key: t('user.warehouse') }))
                            } else if (formData.repository.length > 100) {
                                callback(t('tip.warehouseAddressRegTip'))
                            } else if (!reg.test(formData.repository)) {
                                const tip = store.getters.isEnglish
                                    ? 'Warehouse address must start with git@ or https:// or http:// and end with .git'
                                    : '仓库地址须以 git@ 或 https:// 或 http:// 开头，以.git结尾'
                                callback(tip)
                            }
                        } else {
                            formData.repository = ''
                        }
                    }
                }
            ]
        }

        watch(
            () => props.showModal,
            (val) => {
                if (val) {
                    if (props.operation) {
                        formDom.value.resetFields()
                        formData.name = ''
                        warehouseType.value = 1
                    } else {
                        formData.name = props.projData.name
                        formData.remark = props.projData.remark
                    }
                    formData.platform = props.platforms[0]
                }
                visible.value = val
            }
        )
        const changeWarehouse = () => {
            if (warehouseType.value === 0) formDom.value.validateField('repository')
        }
        const handleClose = () => {
            loading.value = false
            formDom.value.resetFields()
            ctx.emit('closeModal')
        }
        const copySSH = () => {
            http.project.getUserSSHkey().then(({ data }) => {
                clipboard(data.publicKey || '')
                msg.clear('top')
                msg.info(t('copied'))
            })
        }
        const handleSubmit = () => {
            formDom.value.validate((errors) => {
                if (!errors) {
                    loading.value = true
                    const params = {}
                    let api = null
                    params.name = formData.name
                    params.remark = formData.remark
                    if (props.operation) {
                        params.teamId = store.state.userData.teamId
                        params.platform = formData.platform
                        params.repository = formData.repository
                        api = http.project.addProject(params)
                    } else {
                        params.workspaceId = props.projData.id
                        api = http.project.modifyProject(params)
                    }

                    api.then(() => {
                        loading.value = false
                        ctx.emit('updateData')
                        props.operation ? msg.info(t('submitSucc')) : msg.info(t('saveSucc'))
                        ctx.emit('closeModal', formData)
                    }).catch(() => {
                        loading.value = false
                    })
                }
            })
        }

        const validateField = (field) => {
            formDom.value.validateField(field)
        }

        return { warehouseType, visible, formData, formRule, formDom, loading, store, t, copySSH, handleSubmit, handleClose, changeWarehouse, validateField }
    }
})
</script>

<style lang="less" scoped>
:deep(.warehouse-item) {
    flex-wrap: wrap;
    .warehouse {
        flex: 0 0 100%;
    }
}
.scroll-box {
    width: 526px;
    padding-right: 12px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
    margin-left: -20px;
    padding-left: 20px;
    :deep(.arco-form-item-content) {
        position: relative;
    }
    .arco-typography {
        left: -20px;
        top: 4px;
    }
}
.platform-btn {
    flex: 0.33;
    height: 32px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #c8ccd2;
    cursor: pointer;
    color: #666;
    border-radius: 4px;
    &.active {
        border-color: #2b32c9;
        color: #2b32c9;
        :deep(.svg-icon) {
            fill: #2b32c9 !important;
        }
    }
    &:nth-child(2) {
        margin-left: 10px;
    }
    &:nth-child(3) {
        margin-left: 10px;
    }
}
.arco-typography {
    position: absolute;
    left: 0;
    margin-bottom: 6px;
    display: inline-block;
    height: 18px;
    width: 14px;
}
.form-title {
    font-size: 18px;
    font-weight: 700;
    color: #666666;
}
.form-item-title {
    font-size: 14px;
    color: #999999;
    width: 200px;
}
.arco-form-item-label-col {
    display: none;
}
.form-item-view {
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    span:first-child {
        color: #999999;
    }
}
.form-tip {
    font-size: 12px;
    color: #999;
    margin-bottom: -10px;
    span {
        color: #2bc954;
        cursor: pointer;
        text-decoration: underline;
    }
}
</style>
